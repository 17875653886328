// src/components/CheckoutForm.js
import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

function CheckoutForm({ clientSecret }) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !clientSecret) {
            return;
        }


        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://trsnils-donation.de/donation-success', // Update with your success URL
            },
            redirect: 'if_required'
        });

        if (result.error) {
            console.log(result.error.message);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                window.location.reload();
                console.log('Payment successful!');
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full">
            <PaymentElement />
            <button type="submit" disabled={!stripe || !clientSecret} className="w-full p-4 mt-4 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg">
                Spenden
            </button>
        </form>
    );
}

export default CheckoutForm;
