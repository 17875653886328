// src/components/DonationPage.js
import React, { useState } from 'react';
import PersonalDetailsForm from './PersonalDetailsForm';
import EventSelectionForm from './EventSelectionForm';
import PaymentForm from './PaymentForm';

function DonationPage() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: '',
        username: '',
        message: '',
        amount: 0,
        event: '',
    });

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);


    const handleChange = (input) => (e) => {
        setFormData({ ...formData, [input]: e.target.value });
        console.log(formData)
    };

    const handleAmountChange = (amount) => {
        setFormData({ ...formData, amount });
    };


    switch (step) {
        case 1:
            return (
                <PersonalDetailsForm
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleAmountChange={handleAmountChange}
                    nextStep={nextStep}
                />
            );
        case 2:
            return (
                <EventSelectionForm
                    formData={formData}
                    handleChange={handleChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                />
            );
        case 3:
            return (
                <PaymentForm
                    formData={formData}
                    prevStep={prevStep}
                />
            );
        default:
            return <h1 className="text-white">Something went wrong</h1>;
    }
}

export default DonationPage;
