// src/components/EventSelectionForm.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

function EventSelectionForm({ formData, handleChange, nextStep, prevStep }) {
    const [selectedEvent, setSelectedEvent] = useState('tts-message');
    const [sounds, setSounds] = useState([]);
    const [videos, setVideos] = useState([]);
    const [playingSound, setPlayingSound] = useState(null);
    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const audioRefs = useRef({});
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        const fetchSounds = async () => {
            const { data } = await axios.get('https://backend.trsnils-donation.de/sounds');
            setSounds(data);
        };

        const fetchVideos = async () => {
            const { data } = await axios.get('https://backend.trsnils-donation.de/videos');
            setVideos(data);
        };

        fetchSounds();
        fetchVideos();
    }, []);

    const handlePlayPause = (soundUrl, event) => {
        event.stopPropagation();  // Prevent the event from bubbling up to the parent button
        const audio = audioRefs.current[soundUrl];
        if (playingSound === soundUrl) {
            audio.pause();
            setPlayingSound(null);
        } else {
            if (playingSound) {
                audioRefs.current[playingSound].pause();
            }
            audio.play();
            setPlayingSound(soundUrl);
        }
    };

    const openVideoModal = (videoUrl, event) => {
        event.stopPropagation();  // Prevent the event from bubbling up to the parent button
        setCurrentVideoUrl(videoUrl);
        setVideoModalIsOpen(true);
    };

    const closeVideoModal = () => {
        setVideoModalIsOpen(false);
        setCurrentVideoUrl('');
    };

    const renderEventInput = () => {
        switch (selectedEvent) {
            case 'tts-message':
                return (
                    <p>Lasse im Stream deine Message vorlesen!</p>
                );
            case 'sound-alert':
                return (
                    <div className="grid grid-cols-3 gap-4">
                        {sounds.map(sound => (
                            <div key={sound.url}>
                                <button
                                    onClick={() => {
                                        handleChange('sound')({ target: { value: sound.url }})
                                        setSelectedAudio(sound.name)
                                    }}
                                    className={`w-full rounded text-white flex items-center justify-between ${sound.name === selectedAudio ? 'bg-blue-500' : 'bg-gray-600'} hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 p-2`}
                                    data-tip={sound.name}
                                >
                                    <span className="block truncate max-w-xs">{sound.name}</span>
                                    <button
                                        onClick={(event) => handlePlayPause(sound.url, event)}
                                        className="text-white hover:text-blue-500 focus:outline-none ml-2"
                                    >
                                        {playingSound === sound.url ? '❚❚' : '►'}
                                    </button>
                                </button>
                                <audio ref={el => (audioRefs.current[sound.url] = el)} src={sound.url} onEnded={() => setPlayingSound(null)} />
                            </div>
                        ))}
                    </div>
                );
            case 'video-alert':
                return (
                    <div className="grid grid-cols-3 gap-4">
                        {videos.map(video => (
                            <div key={video.url}>
                                <button
                                    onClick={() => {
                                        handleChange('video')({ target: { value: video.url }})
                                        setSelectedVideo(video.name)
                                    }}
                                    className={`w-full rounded text-white flex items-center justify-between ${video.name === selectedVideo ? 'bg-blue-500' : 'bg-gray-600'} hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 p-2`}
                                >
                                    <div className="relative group w-4/5">
                                        <span className="block truncate">{video.name}</span>
                                        <span className="absolute left-0 right-0 bottom-0 h-full text-center whitespace-nowrap overflow-hidden group-hover:animate-marquee">
                                            {video.name}
                                        </span>
                                    </div>
                                    <button
                                        onClick={(event) => openVideoModal(video.url, event)}
                                        className="text-white hover:text-blue-500 focus:outline-none ml-2"
                                    >
                                        ►
                                    </button>
                                </button>
                            </div>
                        ))}
                        <Modal
                            isOpen={videoModalIsOpen}
                            onRequestClose={closeVideoModal}
                            contentLabel="Video Modal"
                            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
                        >
                            <div className="relative bg-gray-900 p-4 rounded-lg w-full max-w-3xl">
                                <button onClick={closeVideoModal} className="absolute top-2 right-2 text-white text-2xl">&times;</button>
                                <video controls className="w-full" autoPlay onEnded={closeVideoModal}>
                                    <source src={currentVideoUrl} type="video/mp4" />
                                </video>
                            </div>
                        </Modal>
                    </div>
                );
            default:
                return null;
        }
    };

    const isNextButtonEnabled = () => {
        if (selectedEvent === 'tts-message') {
            return true;
        } else if (selectedEvent === 'sound-alert') {
            return !!selectedAudio;
        } else if (selectedEvent === 'video-alert') {
            return !!selectedVideo;
        }
        return false;
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
                <div className="relative w-full mb-6">
                    <img src="/assets/img/logo.png" alt="Profile" className="w-full h-48 object-cover rounded-t-lg"/>
                    <div
                        className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center py-3 rounded-b-lg">
                        <h1 className="text-2xl font-semibold">Spende trsnils</h1>
                    </div>
                </div>
                <h2 className="text-xl mb-4">Wählen Sie ein Ereignis aus</h2>
                <div className="flex mb-4 space-x-2">
                    <button
                        onClick={() => setSelectedEvent('tts-message')}
                        className={`w-1/3 p-3 rounded ${selectedEvent === 'tts-message' ? 'bg-blue-500' : 'bg-gray-600'} text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    >
                        TTS-Message
                    </button>

                    {
                        formData.amount >= 200 ? (
                            <button
                                onClick={() => setSelectedEvent('sound-alert')}
                                className={`w-1/3 p-3 rounded ${selectedEvent === 'sound-alert' ? 'bg-blue-500' : 'bg-gray-600'} text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                            >
                                Sound-Alert
                            </button>
                        ) : null
                    }

                    {
                        formData.amount >= 500 ? (
                            <button
                                onClick={() => setSelectedEvent('video-alert')}
                                className={`w-1/3 p-3 rounded ${selectedEvent === 'video-alert' ? 'bg-blue-500' : 'bg-gray-600'} text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                            >
                                Video-Alert
                            </button>
                        ) : null
                    }
                </div>
                <hr className="w-full m-auto p-3"/>
                {renderEventInput()}
                <div className="flex justify-between mt-4">
                    <button onClick={prevStep}
                            className="w-full p-3 rounded bg-blue-500 text-white hover:bg-blue-600 mr-2">Zurück
                    </button>
                    <button
                        onClick={nextStep}
                        disabled={!isNextButtonEnabled()}
                        className={`w-full p-3 rounded ${isNextButtonEnabled() ? 'bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-green-500' : 'bg-gray-500 cursor-not-allowed'} text-white ml-2`}
                    >
                        Weiter
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EventSelectionForm;
