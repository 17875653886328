// src/components/PaymentForm.js
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51PdWITIT85PoJPmQcOWHMqG9IcxBXLaEGIvmNcd2PE23WzyzbDDcSWsOwPqUfIY9xULTs55PrA3Jcvyi5jpT98cX00BccRFmip');

function PaymentForm({ formData }) {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        const fetchClientSecret = async () => {
            const { data } = await axios.post('https://backend.trsnils-donation.de/create-payment-intent', {
                amount: formData.amount,
                email: formData.email,
                metaData: formData
            });
            setClientSecret(data.clientSecret);
        };

        if (formData.amount > 0) {
            fetchClientSecret();
        }
    }, [formData.amount]);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#ffffff',
            colorBackground: '#232946',
            colorText: '#ffffff',
            colorDanger: '#ff4757',
            fontFamily: 'Arial, sans-serif',
            spacingUnit: '4px',
            borderRadius: '5px',
        },
        rules: {
            '.Label': {
                color: '#ffffff',
            },
            '.Input': {
                color: '#ffffff',
            },
            '.Error': {
                color: '#ff4757',
            },
        },
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
                <div className="relative w-full mb-6">
                    <img src="/assets/img/logo.png" alt="Profile" className="w-full h-48 object-cover rounded-t-lg" />
                    <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center py-3 rounded-b-lg">
                        <h1 className="text-2xl font-semibold">Spende trsnils</h1>
                    </div>
                </div>
                {clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                        <CheckoutForm clientSecret={clientSecret} />
                    </Elements>
                )}
                <div className="flex justify-between mt-4">
                    <button onClick={() => window.location.reload()} className="w-full p-3 rounded bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-yellow-500">Donation Abbrechen</button>
                </div>
            </div>
        </div>
    );
}

export default PaymentForm;
