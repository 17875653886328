// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DonationPage from './components/DonationPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/donate" element={<DonationPage />} />
                <Route path="/" element={<Navigate to="/donate" />} />
                <Route path="/donation-success" element={<Navigate to="/donate" />} />
            </Routes>
        </Router>
    );
}

export default App;
