import React, { useState } from 'react';

function PersonalDetailsForm({ formData, handleChange, handleAmountChange, nextStep }) {
    const [errors, setErrors] = useState({});
    const [customAmount, setCustomAmount] = useState('');
    const amounts = [500, 1000, 2000, 5000];

    const validate = () => {
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        if (!formData.username) {
            newErrors.username = 'Username is required';
        }
        if (!formData.amount || (formData.amount === 'custom' && customAmount <= 0)) {
            newErrors.amount = 'Amount is required and must be greater than 0';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNextStep = () => {
        if (validate()) {
            if (formData.amount === 'custom') {
                handleAmountChange(customAmount * 100);
            }
            nextStep();
        }
    };

    const handleCustomAmountChange = (e) => {
        setCustomAmount((e.target.value));
        handleChange({ target: { name: 'amount', value: 'custom' } });
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
            <div className="bg-gray-800 p-8 max-w-lg w-full rounded-lg shadow-lg overflow-hidden">
                <div className="relative w-full mb-6">
                    <img src="/assets/img/logo.png" alt="Profile" className="w-full h-56 object-cover rounded-t-lg"/>
                    <div
                        className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center py-3 rounded-b-lg">
                        <h1 className="text-2xl font-semibold">Spende trsnils</h1>
                    </div>
                </div>
                <div className="p-1">
                    <label className="mb-4 block text-gray-400 font-medium">Email
                        <input
                            type="email"
                            value={formData.email}
                            onChange={handleChange("email")}
                            required
                            name="email"
                            className="w-full p-3 mt-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.email && <p className="text-red-500 text-sm mb-2">{errors.email}</p>}
                    </label>
                    <label className="mb-4 block text-gray-400 font-medium">Username
                        <input
                            type="text"
                            value={formData.username}
                            onChange={handleChange("username")}
                            required
                            name="username"
                            className="w-full p-3 mt-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.username && <p className="text-red-500 text-sm mb-2">{errors.username}</p>}
                    </label>
                    <label className="mb-4 block text-gray-400 font-medium">Message
                        <textarea
                            value={formData.message}
                            onChange={handleChange("message")}
                            name="message"
                            rows="3"
                            className="w-full p-3 mt-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </label>
                </div>
                <div className="flex justify-between mb-4 space-x-2">
                    {amounts.map(amount => (
                        <button
                            key={amount}
                            className={`w-1/5 p-2 rounded ${formData.amount === amount ? 'bg-blue-500' : 'bg-gray-600'} ml-2 py-2 px-4 rounded-lg cursor-pointer transition duration-300 ease-in-out  text-white`}
                            onClick={() => handleAmountChange(amount)}
                        >
                            {amount / 100}€
                        </button>
                    ))}
                    <button
                        className={`w-1/5 p-2 rounded ${formData.amount === 'custom' ? 'bg-blue-500' : 'bg-gray-600'} ml-2 py-2 px-4 rounded-lg cursor-pointer transition duration-300 ease-in-out text-white`}
                        onClick={() => handleAmountChange('custom')}
                    >
                        Custom
                    </button>
                </div>
                {formData.amount === 'custom' && (
                    <>
                        <input
                            type="number"
                            value={customAmount}
                            onChange={handleCustomAmountChange}
                            placeholder="Custom Amount"
                            className="w-full p-3 mb-4 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.amount && <p className="text-red-500 text-sm mb-2">{errors.amount}</p>}
                    </>
                )}
                <button
                    onClick={handleNextStep}
                    className="w-full p-3 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Weiter
                </button>
            </div>
        </div>
    );
}

export default PersonalDetailsForm;
