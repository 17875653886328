import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

const stripePromise = loadStripe('pk_live_51PdWITIT85PoJPmQcOWHMqG9IcxBXLaEGIvmNcd2PE23WzyzbDDcSWsOwPqUfIY9xULTs55PrA3Jcvyi5jpT98cX00BccRFmip');

ReactDOM.render(
    <React.StrictMode>
        <Elements stripe={stripePromise}>
            <App />
        </Elements>
    </React.StrictMode>,
    document.getElementById('root')
);
